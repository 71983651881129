.footer,
.footer * {
  box-sizing: border-box;





}

.footer {
  background: radial-gradient(467.81% 170.43% at 146.40% -51.38%, #EA5A0B 0%, #0A0A0A 80%);
  padding: 70px 18px 0px 18px;

  .terms {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 4rem 0;

    .term-container {
      display: flex;
      flex-wrap: wrap;
      gap: 48px;
      align-items: flex-start;
      justify-content: center;
      list-style: none;
      color: white;
      letter-spacing: 1px;
      a{
        text-decoration: none;
        color: white;
      }
    }
  }

  .all-rights-reserved {
    color: var(--gray-400, #8896ab);
    text-align: center;
    font: var(--text-xl-medium, 500 20px/30px "Poppins", sans-serif);
    padding: 4rem 0;
  }
}