
@import "./variables/variables.scss";
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: $DiscriptoFontFamily;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #241c15;
    font-size: 1rem;
    box-sizing: border-box;
    
}
h1, h2, h3, h4, h5, h6 {
   font-family: $headerFontFamily;
   margin-block-start: 0.5rem ;
   margin-block-end: 1rem ;
   margin-block-start: 0.5rem;
   margin-block-end: 1rem;
}
.h1-lg-center{
   text-align: center;
   color: #343030;
   font-style: italic;
   font-size: xxx-large;
   font-weight: 900;
}
.h2-lg{
   font-size: xx-large;
   line-height: 1.2em;
   font-weight: 700;
   color: $primary-1;
}
.h2-lg-center{
   font-size: xx-large;
   line-height: 1.2em;
   font-weight: 700;
   color: $primary-1;
   text-align: center;
}
.h2-1{
   font-size: 1.5rem;
   line-height: 1.2em;
   font-weight: 600;
   color: $primary-1;
}
.sub-header {
   color: $theamColor3;
   font-size: 1.25rem;
   text-align: center;
}
p{
   color: #241c15;
   font-size: 1.125rem;
   line-height: 1.5rem;
   margin-block-start: 14px;
   margin-block-end: 14px;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
img{
   -webkit-user-drag: none;
}
a{
   color: $theamColor3;
   list-style: none;
   text-decoration: none
}


@import "./react//home.scss";
@import "./react/footer.scss";
@import "./react/webDev.scss";


    ::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
     }
     ::-webkit-scrollbar-track {
        background: rgb(240, 239, 239);
        border-radius: 10px;
     }
     
     ::-webkit-scrollbar-thumb {
        background: rgba(165, 161, 161, 0.479);
        border-radius: 10px;
     }
     ::-webkit-scrollbar-thumb:hover {
        background: #ff6a348a;
     }
     .active{
        color:#E8871E !important
     }
     .pending{
        color :red;
     }
     .not{
        color :green;
     }