@import "../variables/variables.scss";


.div-ekit-heading-separetor-wraper,
.div-ekit-heading-separetor-wraper * {
  box-sizing: border-box;
}

.div-ekit-heading-separetor-wraper {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.div-elementskit-border-divider {
  background: #2575fc;
  border-radius: 2px;
  flex-shrink: 0;
  width: 40px;
  height: 4px;
  position: relative;
}

.pseudo {
  background: #0c5adb;
  border-radius: 2px;
  width: 4px;
  height: 4px;
  position: absolute;
  left: -27px;
  top: 0px;
  box-shadow: 18px 0px 0px 0px rgba(12, 90, 219, 1),
    9px 0px 0px 0px rgba(12, 90, 219, 1);
}

.strong-1 {
  color: gray;
}

.award::before {
  content: "";
  width: calc(55% - 15vw);
  /* border: 1px solid; */
  background: #A5E887;
  height: 3px;
}

.award::after {
  content: "";
  width: calc(55% - 15vw);
  /* border: 1px solid; */
  background: #A5E887;
  height: 3px;
}

.award-img {
  position: relative;
}

.award-img::after {
  content: "";
  position: absolute;
  height: 30px;
  width: 2px;
  top: 50%;
  right: 0;
  translate: 0 -50%;
  background-color: #e7e7e7;
}





.pic-card::after {
  content: "\e97e";
  width: 45px;
  height: 45;
}

.de-x::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #223A52;
  position: absolute;
  top: 0;
}