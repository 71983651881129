@import "../variables/variables.scss";

.web-dev {
    p{
        color: #241c15;
        font-size: 1rem;
        margin-block-end: 10px;
    }
    .heading-2{
        font-size: 3rem;
    }
}